import { graphConfig, loginRequest } from './auth-config';
import { msalInstance } from '../pages/_app';

export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getGroups(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('ConsistencyLevel', 'eventual');

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.myGroups, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getPhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.myPhoto, options)
    .then((response) => response)
    .catch((error) => console.log(error));
}

export const refreshAccessToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account!');
  }

  try {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    return token.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          return resp.idToken;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(error);
    }
  }
};
